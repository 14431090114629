import { Button } from 'src/components/ui/button'
import { Card, CardHeader } from 'src/components/ui/card'
import React, { useEffect, useMemo, useState } from 'react'
import { AlignJustify, Bell, BellIcon, History, LogOut, MapPin, Minus, RotateCcw, Search, User } from 'lucide-react'
import Logo from 'src/assets/images/Electreecity-LogoPNG.png'

import { Link, useLocation } from 'react-router-dom'
// import { Menu } from '../Sidebar/modules'
import dayjs from 'dayjs'
import { SidebarProps } from '../Sidebar/Sidebarlayout'
import { cn, convertToCamelCase } from 'src/helpers'
import { useAuth } from 'src/Modules/Auth/Core'
import { rollBaseModule, rollBaseModuleHeading } from '../Sidebar/rollBaseModule'



const Headers = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {


  const { logout,auth } = useAuth();
  const { pathname } = useLocation();
  const menuOptions = rollBaseModuleHeading(auth?.role&&auth?.role);


  const heading = useMemo(() => {
    for (const cur of menuOptions) {

      if (cur.options && cur.options.length > 0) {
        for (const ind of cur.options) {
          if (ind.path && pathname===ind.path) {
           
            return `${cur.name}/${ind.name}`;
          }
        }
      }
      else if (cur.path && pathname.includes(cur.path)) {
        return cur.name;
      }
      else if (pathname.includes('profile')) {
        return 'My Profile'
      }
    }
    return ''; // Default case if no match found
  }, [menuOptions, pathname]);
  // console.log(heading)
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 6000); // Update every minute
    return () => clearInterval(interval);
  }, []);
  const formattedDate = dayjs(currentTime).format('DD-MM-YYYY');
  const formattedTime = dayjs(currentTime).format('HH:mm ');
const formatedDate=dayjs(currentTime).format("MMM-YYYY")
  return (


    <header className="w-full sticky top-0 z-9 flex bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-md drop-shadow-md lg:rounded-t-md">
      <nav className="w-full flex items-center justify-between h-12 px-1  md:py-4 md:px-6">

        <div className='text-sm text-textLightBlack font-normal flex  items-center space-x-4'>
          <div className='md:hidden sm:flex'>
            <div className={cn(' p-1 bg-gray-200 rounded-md flex',{"hidden":sidebarOpen})} onClick={() => setSidebarOpen(!sidebarOpen)}>
              <AlignJustify />
            </div>
          </div>
          <div className='text-sm'> {heading}</div>

        </div>
        <div className='flex space-x-2 items-center'>
       
          <div className='w-full py-4 px-8'>
            <div className='w-full flex justify-between items-center'>
              {/* <div className='w-full text-base font-bold text-textLightBlack'>{heading}</div> */}
              <div className='w-full text-xs font-Roboto  font-normal text-textLightBlack  whitespace-nowrap flex space-x-2 justify-end items-center'>
                {/* <span className='space-x-2 text-sm font-normal font-Roboto text-textLightBlack '>
                  <span>{formattedDate}</span>
                  <span>{formattedTime}</span>
                </span> */}
                {/* <span>|</span> */}
                {/* <span><RotateCcw size={16} /></span> */}
                {/* <span className='text-sm font-normal font-Roboto text-textLightBlack'>Pull to Refresh</span> */}
                <span className='text-sm'>{pathname.includes('dashboard')?formatedDate:""}</span>
              </div>
            </div>
          </div>
          {/* <span className='bg-iconBg rounded-full p-1'><History color='#FFFFFF' size={20} /></span>
          <span className='bg-iconBg rounded-full p-1'><BellIcon color='#FFFFFF' size={20} /></span> */}
          
          <span className='bg-primaryDarkBlue rounded-full p-1' title='Profile' ><Link to='/profile'><User color='#FFFFFF' size={20} /></Link></span>
          <span className='flex text-textLightBlack text-sm font-normal whitespace-nowrap' title='Username'>{auth?.user_name||"-"}</span>
          
        </div>
      </nav>
    </header>



  )
}

export default Headers