import axios, { AxiosError, AxiosResponse } from "axios";
import { ADD_USER_URL, ALL_USER_LIST, BILL_API_URL, BLOCKCHAIN_DETAIL_API, BLOCKCHAIN_ID_API, BLOCKCHAIN_TRANSACTION_DETAILS_API, CREATE_TARRIF_PLAN, CONSUMPTIONGENRATIONSETTLEMENT, DAILY_TRANSACTION_INVOICE_URL, EVENT_TIMELINE_STATTIC, GET_ONBOARD_USER, PARENT_CODE_API, PROFILE_APPROVE_REJECT, TESTING_VERIFICATION_API, TRANSACTION_INVOICE_URL, UPDATE_STATUS, UPDATE_TARRIF_PLAN, URL_GET_ALL_FAILED_TRANSACTIONS, URL_GET_ALL_TARRIF, URL_GET_ALL_TRANSACTIONS, URL_GET_BILL, URL_GET_CONFIGRATION_TARRIF, URL_GET_PREVIOUS_DAY_READING, URL_LOGIN_OTP, URL_LOGIN_OTP_VALIDATE, URL_METABASE, URL_METER_READING_REPORT, URL_UPDATE_CONFIGRATION_TARRIF, URL_UPDATE_METER_READING, URL_USER_LIST, URL_VERIFY_TOKEN, USER_DASHBOARD_SUMMARY, USER_LOAD_METER_PLANT_DETAIL, USER_PROFILE, URL_GET_ALL_GROSS_CAPTURE, URL_UPDATE_GROSS_CAPTURE_FORM, URL_CREATE_GROSS_CAPTURE_FORM, URL_METABASE_CARD_URL, URL_GET_ALL_BANNER_LIST, URL_CREATE_BANNER_LIST, URL_UPDATE_BANNER_LIST, URL_UPLOAD_IMAGE, URL_CREATE_METABASE_FORM, URL_UPDATE_METABASE_FORM, URL_DISCOM_HISTORY, CONSUMER_DETAILS_URL, URL_UPDATE_GATE_CLOSURE, URL_CREATE_GATE_CLOSURE, URL_GET_ALL_GATE_CLOSURE, URL_DOWNLOAD_METER_READING, URL_METER_READING_SUMMARY, URL_SEND_BILL_EMAIL } from "./apis";
import { axiosWithToken } from "./axioseSetup";
import { getAuth } from "src/Modules/Auth/Core";
import { Response } from "../helpers";


// Dashboard api
export function getBlockchainDetails(query: string): Promise<AxiosResponse> {

  return axiosWithToken.get(`${BLOCKCHAIN_DETAIL_API}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getBlockchainIdData(query: string): Promise<AxiosResponse> {

  return axiosWithToken.get(`${BLOCKCHAIN_ID_API}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getTransactionDetailsApi(id: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${BLOCKCHAIN_TRANSACTION_DETAILS_API(id)}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}


export function getUserDashboardSummaryApi(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${USER_DASHBOARD_SUMMARY}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getEventTimelineApi(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${EVENT_TIMELINE_STATTIC}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}


export function getConsumptionGenrationLineChart(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${CONSUMPTIONGENRATIONSETTLEMENT}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}
// Testing Verification APi function
export function getTestingVerificationApi(query?: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${TESTING_VERIFICATION_API}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// user list

export function getAllUserApi(query: string): Promise<AxiosResponse> {

  return axiosWithToken
    .get(`${ALL_USER_LIST}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}


export function getOnboardUserApi(query: string): Promise<AxiosResponse> {

  return axiosWithToken
    .get(`${GET_ONBOARD_USER}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getUserProfile(id: any): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${USER_PROFILE(id)}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getUserLoadMeterPlantDetail(id: any): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${USER_LOAD_METER_PLANT_DETAIL(id)}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data)
}

export function verifyUser(postData: any): Promise<AxiosResponse> {
  return axiosWithToken.post(`${PROFILE_APPROVE_REJECT}`, postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data)
}

export function postAddUser(postData: any): Promise<AxiosResponse> {
  return axiosWithToken.post(`${ADD_USER_URL}`, postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data)
}


export function updateStatusUser(postData: any): Promise<AxiosResponse> {
  return axiosWithToken.put(`${UPDATE_STATUS}`, postData)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data)
}

// Login API

export const loginApi = (postData: any) => {
  return axiosWithToken
    .post(`${URL_LOGIN_OTP}`, postData)
    .then((d: AxiosResponse<any>) => d?.data)
    .catch((d: AxiosError) => d.response?.data)
};

export const loginOtpValidateApi = (postData: any) => {
  return axiosWithToken
    .post(`${URL_LOGIN_OTP_VALIDATE}`, postData)
    .then((d: AxiosResponse<any>) => d?.data)
    .catch((d: AxiosError) => d.response?.data)
};

export function getUserByToken(): Promise<AxiosResponse> {
  const auth = getAuth();
  return axiosWithToken
    .post(URL_VERIFY_TOKEN)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data)
}




// CONFIGRATION PANEL & TARRIF

export const updateConfigrationPanelTarrif = (postData: any) => {
  return axiosWithToken
    .put(`${URL_UPDATE_CONFIGRATION_TARRIF}`, postData)
    .then((d: AxiosResponse<any>) => d?.data)
    .catch((d: AxiosError) => d.response?.data)
};

export function getcongirationTarrifPanel(id: any): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_GET_CONFIGRATION_TARRIF(id)}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data)
}


// BILL 

export function getBillApi(query: string): Promise<AxiosResponse> {

  return axiosWithToken
    .get(`${URL_GET_BILL}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data)
}

export function sendBillEmailApi(query: string): Promise<AxiosResponse> {

  return axiosWithToken
    .get(`${URL_SEND_BILL_EMAIL}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data)
}

// tarrif


export function getAllTarrifApi(query: string): Promise<AxiosResponse> {

  return axiosWithToken
    .get(`${URL_GET_ALL_TARRIF}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data)
}

export const createTarrifApi = (postData: any) => {
  return axiosWithToken
    .post(`${CREATE_TARRIF_PLAN}`, postData)
    .then((d: AxiosResponse<any>) => d?.data)
    .catch((d: AxiosError) => d.response?.data)
};

export const updateTarrifApi = (id: string, postData: any) => {
  return axiosWithToken
    .put(`${UPDATE_TARRIF_PLAN(id)}`, postData)
    .then((d: AxiosResponse<any>) => d?.data)
    .catch((d: AxiosError) => d.response?.data)
};

export function getAllParentCodeApi(): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${PARENT_CODE_API}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data)
}

// Transactions
export function getAllTransactionsNew(query: string): Promise<AxiosResponse> {

  return axiosWithToken
    .get(`${URL_GET_ALL_TRANSACTIONS}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data)
}
export function getAllFailedTransactions(query: string): Promise<AxiosResponse> {

  return axiosWithToken
    .get(`${URL_GET_ALL_FAILED_TRANSACTIONS}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data)
}


export const getMetaReportUrl = (dashboard_no: any,params?:any): Promise<any | undefined> => {

  return axiosWithToken
    .post(URL_METABASE, {
      "resource": {
        "dashboard": dashboard_no?Number(dashboard_no):""
      },
      "params": params||{}
      // "params": { ...postData }
    })
    .then((response: AxiosResponse<Response<any>>) => response)
    .then((response: Response<any>) => response.data)
    .catch((d: AxiosError) => d.response?.data)
}

export function getAllMetabaseCard(): Promise<AxiosResponse> {

  return axiosWithToken
    .get(`${URL_METABASE_CARD_URL}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data)
}

export const createMetabaseForm = (postData: any) => {
  return axiosWithToken
    .post(`${URL_CREATE_METABASE_FORM}`, postData)
    .then((d: AxiosResponse<any>) => d?.data)
    .catch((d: AxiosError) => d.response?.data)
};

export const updateMetabaseForm = (id:string,postData: any) => {
  return axiosWithToken
    .put(`${URL_UPDATE_METABASE_FORM(id)}`, postData)
    .then((d: AxiosResponse<any>) => d?.data)
    .catch((d: AxiosError) => d.response?.data)
};

// bill
export function getBillDetails(query?: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${BILL_API_URL}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getDailyTransactionInvoice(query?: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${DAILY_TRANSACTION_INVOICE_URL}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getTransactionInvoice(query?: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${TRANSACTION_INVOICE_URL}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function getConsumerDetails(query: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${CONSUMER_DETAILS_URL(query)}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// Drop down url

export function getAlluserListDropDown(): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_USER_LIST}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

// Meter Reading Report

export function dailyMeterReadingReport(query?: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_METER_READING_REPORT}?${query}`)
    .then((d: AxiosResponse) => d.data?.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function previousMeterReadingReport(query?: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_GET_PREVIOUS_DAY_READING}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function doenloadMeterReadingReport(query?: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_DOWNLOAD_METER_READING}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export function meterReadingSummary(query?: string): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_METER_READING_SUMMARY}?${query}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export const updateMeterReadingReport = (postData: any) => {
  return axiosWithToken
    .put(`${URL_UPDATE_METER_READING}`, postData)
    .then((d: AxiosResponse<any>) => d?.data)
    .catch((d: AxiosError) => d.response?.data)
};

// gross capture form

export function getAllGrossCaptureDate(): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_GET_ALL_GROSS_CAPTURE}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export const updateGrossCaptureForm = (postData: any,id:string) => {
  return axiosWithToken
    .put(`${URL_UPDATE_GROSS_CAPTURE_FORM(id)}`, postData)
    .then((d: AxiosResponse<any>) => d?.data)
    .catch((d: AxiosError) => d.response?.data)
};

export const createGrossCaptureForm = (postData: any) => {
  return axiosWithToken
    .post(`${URL_CREATE_GROSS_CAPTURE_FORM}`, postData)
    .then((d: AxiosResponse<any>) => d?.data)
    .catch((d: AxiosError) => d.response?.data)
};


// BANNER API

export function getAllBannerList(): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_GET_ALL_BANNER_LIST}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export const createBannerForm = (postData: any) => {
  return axiosWithToken
    .post(`${URL_CREATE_BANNER_LIST}`, postData)
    .then((d: AxiosResponse<any>) => d?.data)
    .catch((d: AxiosError) => d.response?.data)
};

export const updateBannerForm = (id:string,postData: any) => {
  return axiosWithToken
    .put(`${URL_UPDATE_BANNER_LIST(id)}`, postData)
    .then((d: AxiosResponse<any>) => d?.data)
    .catch((d: AxiosError) => d.response?.data)
};

export const uploadBannerImage = (postData: any) => {
  return axiosWithToken
    .post(`${URL_UPLOAD_IMAGE}`, postData)
    .then((d: AxiosResponse<any>) => d?.data)
    .catch((d: AxiosError) => d.response?.data)
};

// Discom History
export const discomHistory = (id:string,postData: any) => {
  return axiosWithToken
    .post(`${URL_DISCOM_HISTORY(id)}`, postData)
    .then((d: AxiosResponse<any>) => d?.data)
    .catch((d: AxiosError) => d.response?.data)
};


// Gate closure

export function getGateAllGateClosureList(): Promise<AxiosResponse> {
  return axiosWithToken
    .get(`${URL_GET_ALL_GATE_CLOSURE}`)
    .then((d: AxiosResponse) => d.data)
    .catch((d: AxiosError) => d.response?.data);
}

export const updateGateClosureTime = (id:string,postData: any) => {
  return axiosWithToken
    .put(`${URL_UPDATE_GATE_CLOSURE(id)}`, postData)
    .then((d: AxiosResponse<any>) => d?.data)
    .catch((d: AxiosError) => d.response?.data)
};

export const CreateGateClosure = (postData: any) => {
  return axiosWithToken
    .post(`${URL_CREATE_GATE_CLOSURE}`, postData)
    .then((d: AxiosResponse<any>) => d?.data)
    .catch((d: AxiosError) => d.response?.data)
};

