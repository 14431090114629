import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import dayjs from "dayjs";
import { string } from "yup";


interface IMeataBaseReportData {
data:any;
start_date:string,
end_date:string,
params:any
}


const initialState: IMeataBaseReportData = {
   
    data: {},
start_date:'',
end_date:'',
params:{}

}

const metabaseReportSlicer = createSlice({
    name: 'MetabaseReport/slice',
    initialState,
    reducers: {

        updateMetabaseData(state, action: PayloadAction<any>) {
            state.data = action.payload;
        },
        updateMetabaseDate(state, action: PayloadAction<any>) {
            state.start_date = action.payload?.start_date;
            state.end_date=action.payload?.end_date
        },
        updateParams(state, action: PayloadAction<any>) {
            state.params = action.payload;
        },
        resetState: (state) => {
            return initialState;
          },

    }
})


export const { updateMetabaseData,updateMetabaseDate,updateParams,resetState } = metabaseReportSlicer.actions;

export const metabaseReportReducerState = (state: RootState) => state.metabase;
export default metabaseReportSlicer.reducer;