import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
const QUERIES = {
  USERS_LIST: "users-list",
};

const months: any = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];
const MonthNumbers: any = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

const startCase = (string: any) => {
  return (
    string &&
    string
      ?.replace(/[\W_]+/g, " ")
      .replace(/(?<=[a-z])([A-Z])/g, " $1")
      .replace(/^[a-z]/, (match: any) => match.toUpperCase())
  );
};

const camelCase = (string: any) => {
  return (
    string &&
    string
      ?.replace(/[\W_]+(.|$)/g, (_: any, chr: any) => " " + chr.toUpperCase())
      .trim()
  );
};

export const toPercent = (value: any) => {
  return (value * 100).toFixed(2);
};

export const convertMinutesToHoursAndMinutes = (mins: number): string => {
  const hours = Math.floor(mins / 60);
  const remainingMinutes = mins % 60;

  return remainingMinutes == 0
    ? `${hours} h`
    : `${hours} h ${remainingMinutes} m`;
};

export const getDatesOfMonth = (dateString: string): string[] => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth();
  const daysInMonth = new Date(year, month + 1, 0).getDate();

  const datesArray: string[] = [];
  for (let i = 1; i <= daysInMonth; i++) {
    const newDate = new Date(year, month, i);
    const formattedDate = `${newDate.getFullYear()}-${String(
      newDate.getMonth() + 1
    ).padStart(2, "0")}-${String(newDate.getDate()).padStart(2, "0")}`;
    datesArray.push(formattedDate);
  }

  return datesArray;
};

export const toINR = (value: number) => {
  return value?.toLocaleString("en-UK", {
    maximumFractionDigits: 0,
    // style: 'currency',
    // currency: 'INR'
  });
};

const isNumeric = (num: any) =>
  (typeof num === "number" || (typeof num === "string" && num.trim() !== "")) &&
  !isNaN(num as number);

const isValidStr = (str: any, isCampleCase = false) => {
  if (str != null && str != undefined) {
    if (isNumeric(str)) {
      return str;
    }

    if (isCampleCase) {
      return startCase(camelCase(str));
    } else return str;
  } else return "-";
};

function convertToCamelCase(str: any) {
  // const words = str.split('_');
  // const camelCaseString = words.map((word: any, index: any) => {
  //   return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  // }).join(' ');
  // return camelCaseString;

  return startCase(camelCase(str));
}

function convertToCamelCaseNormal(str: any) {
  // const result = str.replace(/([A-Z])/g, " $1");
  // const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  // return finalResult;
  return startCase(camelCase(str));
}

const bussinessDaysInMonth = (m: any, y: any) => {
  var days = new Date(y, m, 0).getDate();

  var sundays = [8 - new Date(m + "/01/" + y).getDay()];
  var saturday = [7 - new Date(m + "/01/" + y).getDay()];

  var allDates = [...Array(days)].map((_, i) => i + 1);
  allDates.splice(
    allDates.findIndex((d: any) => d == sundays),
    1
  );
  allDates.splice(
    allDates.findIndex((d: any) => d == saturday),
    1
  );

  for (var i = sundays[0] + 7; i < days; i += 7) {
    sundays.push(i);
    allDates.splice(
      allDates.findIndex((d: any) => d == i),
      1
    );
  }

  for (var i = saturday[0] + 7; i < days; i += 7) {
    saturday.push(i);
    allDates.splice(
      allDates.findIndex((d: any) => d == i),
      1
    );
  }

  var working_until_days = [];
  var remaining_days = [];
  var currentDate = new Date();
  var curr_date = currentDate.getDate();

  for (let index = 0; index < allDates.length; index++) {
    if (allDates[index] < curr_date) {
      working_until_days.push(allDates[index]);
    } else {
      remaining_days.push(allDates[index]);
    }
  }

  //console.log({ sundays, saturday, allDates, working_until_days, remaining_days });

  return {
    holydays: [...sundays, ...saturday].sort(),
    total_working_days: allDates,
    working_until_days,
    remaining_days,
  };
};

const getMonthNumber = (date: any) => {
  var dt = new Date(date);
  // console.log({ dt, date });
  // console.log("MonthNumbers :", MonthNumbers[dt.getMonth()])
  return MonthNumbers[dt.getMonth()];
};

const getFormatDate = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var d = dt.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = dt.getFullYear();
  return y + "-" + m + "-" + d;
};

// const getFormattedDate = (date?: any) => {
//   var dt = date ? new Date(date) : new Date();
//   var d = dt.getDate();
//   var m = MonthNumbers[dt.getMonth()];
//   var y = dt.getFullYear();

//   // Ensure day and month have leading zeros if needed
//   var formattedDay = d < 10 ? '0' + d : d;
//   var formattedMonth = m < 10 ? '0' + m : m;

//   return y + "-" + formattedMonth + "-" + formattedDay;
// }

const getFormattedDate = (date?: any) => {
  const dt = date ? new Date(date) : new Date();
  const d = dt.getDate();
  const m = dt.getMonth() + 1; // Add 1 since getMonth() returns zero-based index
  const y = dt.getFullYear();

  // Ensure day and month have leading zeros if needed
  const formattedDay = d < 10 ? "0" + d : d;
  const formattedMonth = m < 10 ? "0" + m : m;

  return y + "-" + formattedMonth + "-" + formattedDay;
};

// const getStartDateAndEndDateOfMonth = (date?: any) => {
//   var dt = date ? new Date(date) : new Date();

//   var firstDate: any = new Date(dt.getFullYear(), dt.getMonth() + 1, 1);
//   var d = firstDate.getDate(), m = MonthNumbers[dt.getMonth()], y = firstDate.getFullYear();
//   firstDate = getFormattedDate(new Date(y + "-" + m + "-" + d));

//   var lastDate: any = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
//   var d = lastDate.getDate(), m = MonthNumbers[dt.getMonth()], y = lastDate.getFullYear();
//   lastDate = getFormattedDate(new Date(y + "-" + m + "-" + d));

//   return { firstDate, lastDate }

// }

const calculateMonthsFromBym = (billYearMonth?: any) => {
  if (!billYearMonth || typeof billYearMonth !== "string") {
    // Handle invalid or missing input
    return { current: "", previous: "", twoMonthsAgo: "" };
  }

  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const [month, year] = billYearMonth.split("-");

  if (months.indexOf(month) === -1 || isNaN(parseInt(year, 10))) {
    // Handle invalid month or year format
    return { current: "", previous: "", twoMonthsAgo: "" };
  }

  const currentMonthIndex = months.indexOf(month);
  const currentYear = parseInt(year, 10);

  const current = billYearMonth;

  let previousMonthIndex = currentMonthIndex - 1;
  let previousYear = currentYear;
  if (previousMonthIndex < 0) {
    previousMonthIndex = 11; // Set to December
    previousYear--;
  }
  const previous = `${months[previousMonthIndex]}-${previousYear
    .toString()
    .slice(-2)}`;

  let twoMonthsAgoIndex = currentMonthIndex - 2;
  let twoMonthsAgoYear = currentYear;
  if (twoMonthsAgoIndex < 0) {
    twoMonthsAgoIndex += 12;
    twoMonthsAgoYear--;
  }
  const twoMonthsAgo = `${months[twoMonthsAgoIndex]}-${twoMonthsAgoYear
    .toString()
    .slice(-2)}`;

  return { current, previous, twoMonthsAgo };
};

const getStartDateAndEndDateOfMonth = (date?: any) => {
  const dt = date ? new Date(date) : new Date();
  const year = dt.getFullYear();
  const month = dt.getMonth();

  const firstDate = getFormattedDate(new Date(year, month, 1));

  const lastDay = new Date(year, month + 1, 0).getDate();
  const lastDate = getFormattedDate(new Date(year, month, lastDay));

  return { firstDate, lastDate };
};

// export const getStartDateAndEndDateOfMonthUsingBym = (inputDate?: string) => {
//   const dt = inputDate ? parseInputDate(inputDate) : new Date();
//   const year = dt.getFullYear();
//   const month = dt.getMonth();

//   const firstDate = getFormattedDatess(new Date(year, month, 1));

//   const lastDay = new Date(year, month + 1, 0).getDate();
//   const lastDate = getFormattedDatess(new Date(year, month, lastDay));

//   return { firstDate, lastDate };
// };

// const parseInputDate = (inputDate: string): Date => {
//   const [monthAbbr, day] = inputDate.split('-');
//   const monthNames: {[key: string]: number} = {
//     JAN: 0, FEB: 1, MAR: 2, APR: 3, MAY: 4, JUN: 5,
//     JUL: 6, AUG: 7, SEP: 8, OCT: 9, NOV: 10, DEC: 11
//   };
//   const monthIndex = monthNames[monthAbbr.toUpperCase()];
//   const currentYear = new Date().getFullYear();
//   return new Date(currentYear, monthIndex, parseInt(day));
// };

// const getFormattedDatess = (date: Date): string => {
//   const year = date.getFullYear();
//   const month = (date.getMonth() + 1).toString().padStart(2, '0');
//   const day = date.getDate().toString().padStart(2, '0');
//   return `${year}-${month}-${day}`;
// };

export function getStartEndDate(
  dateStr: string
): { startDate: string; endDate: string } | null {
  if (typeof dateStr !== "string") {
    console.error("Input should be a string.");
    return null;
  }

  const dateRegex = /^([A-Z]{3})-(\d{2})$/;
  const match = dateStr.match(dateRegex);

  if (!match) {
    console.error(
      "Invalid date format. Please use the format: MON-YY (e.g., NOV-23)"
    );
    return null;
  }

  const monthAbbreviation = match[1];
  const yearAbbreviation = match[2];

  const monthMap: { [key: string]: number } = {
    JAN: 0,
    FEB: 1,
    MAR: 2,
    APR: 3,
    MAY: 4,
    JUN: 5,
    JUL: 6,
    AUG: 7,
    SEP: 8,
    OCT: 9,
    NOV: 10,
    DEC: 11,
  };

  const monthIndex = monthMap[monthAbbreviation.toUpperCase()];

  if (monthIndex === undefined) {
    console.error("Invalid month abbreviation.");
    return null;
  }

  const yearPrefix = parseInt(yearAbbreviation, 10);
  const year = yearPrefix >= 0 && yearPrefix <= 99 ? 2000 + yearPrefix : null;

  if (year === null) {
    console.error("Invalid year.");
    return null;
  }

  const startDate = new Date(year, monthIndex, 1);
  const endDate = new Date(year, monthIndex + 1, 0);

  const formattedStartDate = startDate.toISOString().split("T")[0];
  const formattedEndDate = endDate.toISOString().split("T")[0];

  return {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
  };
}

export function getMonthRange(
  input: string
): { startDate: string; endDate: string } | null {
  if (typeof input !== "string") {
    console.error("Invalid input. Please provide a valid string.");
    return null;
  }

  const monthRegex: RegExp = /^([A-Za-z]{3})-(\d{2})$/;
  const match: RegExpMatchArray | null = input.match(monthRegex);

  if (!match) {
    console.error(
      "Invalid input format. Please use the format: 'MMM-YY' (e.g., NOV-23)"
    );
    return null;
  }

  const [, monthStr, yearStr]: string[] = match;
  const monthIndex: number = new Date(
    `${monthStr} 1, ${2000 + parseInt(yearStr)}`
  ).getMonth();
  const startDate: Date = new Date(2000 + parseInt(yearStr), monthIndex, 1);
  const endDate: Date = new Date(2000 + parseInt(yearStr), monthIndex + 1, 0);

  // Format dates to ISO strings manually
  const formatISODate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return {
    startDate: formatISODate(startDate),
    endDate: formatISODate(endDate),
  };
}

export const dropdownOptions = [
  { value: "dist", label: "District" },
  { value: "substn", label: "Substation" },
];

const getStartDate = (date?: any) => {
  var dt = date ? new Date(date) : new Date();

  var firstDate: any = new Date(dt.getFullYear(), dt.getMonth() + 1, 1);
  var d = firstDate.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = firstDate.getFullYear();
  firstDate = y + "-" + m + "-" + d;

  return { firstDate };
};

const getStartDateWithoutObject = (date?: any) => {
  var dt = date ? new Date(date) : new Date();

  var firstDate: any = new Date(dt.getFullYear(), dt.getMonth() + 1, 1);
  var d = firstDate.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = firstDate.getFullYear();
  // firstDate =

  return y + "-" + m + "-" + d;
};

const getCurrentMonthYearNo = (_date?: any) => {
  var dt = _date ? new Date(_date) : new Date();
  var date = dt.getDate(),
    month = MonthNumbers[dt.getMonth()],
    year = dt.getFullYear();
  return { month, year, date };
};

const converMonthYearToMonthYearNo = (shortMonthYear?: any) => {
  const [m, y] = shortMonthYear && shortMonthYear.split("-");
  const getMonthIndex = months.findIndex((mm: any) => mm == m);
  var dt = shortMonthYear
    ? new Date("20" + y + "-" + (getMonthIndex + 1) + "-01")
    : new Date();
  var d = dt.getDate(),
    month = MonthNumbers[dt.getMonth()],
    year = dt.getFullYear();
  return { month, year };
};

const getFormatDateKLocale = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var d = dt.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = dt.getFullYear();
  return d + "/" + m + "/" + y;
};

const convertShortMonthToDate = (shortMonthYear?: any) => {
  const [month, year] = shortMonthYear.split("-");
  const getMonthIndex = months.findIndex((m: any) => m == month);
  var m = getMonthIndex + 1;
  return 20 + year + "-" + MonthNumbers[m - 1] + "-" + "01";
};

const getFormatToTime = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var h = dt.getHours(),
    m = dt.getMinutes(),
    s = dt.getSeconds(),
    mm = dt.getMilliseconds();
  var ampm = h >= 12 ? "pm" : "am";
  return h + ":" + m + ":" + s + " " + ampm;
};

export const getFormatToTimeWithoutSeconds = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var h = dt.getHours(),
    m = dt.getMinutes(),
    s = dt.getSeconds(),
    mm = dt.getMilliseconds();
  var ampm = h >= 12 ? "pm" : "am";
  return h + ":" + m + " " + ampm;
};

const getFormatToDateTime = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var h = dt.getHours(),
    min = dt.getMinutes(),
    s = dt.getSeconds(),
    mm = dt.getMilliseconds(),
    d = dt.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = dt.getFullYear();
  var ampm = h >= 12 ? "pm" : "am";
  return (
    y + "-" + m + "-" + d + " " + "," + h + ":" + min + ":" + s + " " + ampm
  );
};

// const getFormatToDateTimeWithoutSecondsTogether = (date?: any) => {
//   var dt = date ? new Date(date) : new Date();
//   var h = dt.getHours(), min = dt.getMinutes(), s = dt.getSeconds(), mm = dt.getMilliseconds(), d = dt.getDate(), m = MonthNumbers[dt.getMonth()], y = dt.getFullYear();
//   var ampm = h >= 12 ? 'pm' : 'am';
//   return y + "-" + m + "-" + d + " " + ", " + h + ":" + min + " " + ampm;
// }

const getFormatToDateTimeWithoutSecondsTogether = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var h = dt.getHours(),
    min = dt.getMinutes(),
    d = ("0" + dt.getDate()).slice(-2),
    m = ("0" + (dt.getMonth() + 1)).slice(-2),
    y = dt.getFullYear();

  var ampm = h >= 12 ? "PM" : "AM";
  h = h % 12;
  h = h ? h : 12; // Handle 0 or 12 for hours
  var formattedTime =
    ("0" + h).slice(-2) + ":" + ("0" + min).slice(-2) + " " + ampm;

  return y + "-" + m + "-" + d + " , " + formattedTime;
};

const getFormatToDateTimeWithoutSeconds = (date?: any) => {
  const dt = date ? new Date(date) : new Date();
  const hours = dt.getHours();
  const minutes = dt.getMinutes();
  const seconds = dt.getSeconds();
  const milliseconds = dt.getMilliseconds();
  const day = dt.getDate();
  const month = MonthNumbers[dt.getMonth()];
  const year = dt.getFullYear();
  const ampm = hours >= 12 ? "pm" : "am";

  const formattedDate = `${year}-${month}-${day}`;
  const formattedTime = `${hours}:${minutes} ${ampm}`;
  // const formattedTime = `${hours}:${minutes}:${seconds}.${milliseconds} ${ampm}`;

  return { date: formattedDate, time: formattedTime };
};

const getFormatToDays = (date?: any) => {
  const currentDate = new Date();
  const providedDate = date ? new Date(date) : new Date();

  const differenceInSeconds = Math.floor(
    (currentDate.getTime() - providedDate.getTime()) / 1000
  );

  if (differenceInSeconds < 60) {
    return `${differenceInSeconds} second${differenceInSeconds !== 1 ? "s" : ""
      } ago`;
  }

  const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  if (differenceInMinutes < 60) {
    return `${differenceInMinutes} minute${differenceInMinutes !== 1 ? "s" : ""
      } ago`;
  }

  const differenceInHours = Math.floor(differenceInMinutes / 60);
  if (differenceInHours < 24) {
    return `${differenceInHours} hour${differenceInHours !== 1 ? "s" : ""} ago`;
  }

  const differenceInDays = Math.floor(differenceInHours / 24);
  return `${differenceInDays} day${differenceInDays !== 1 ? "s" : ""} ago`;
};

const getShortMonthYear = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var d = dt.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = dt.getFullYear();
  return (
    months[dt.getMonth()].toString().toUpperCase() +
    "-" +
    y.toString().slice(-2)
  );
};

const subtractMonthsFromDate = (date?: any, subtractMonths = 1) => {
  var dt = date ? new Date(date) : new Date();
  var d = dt.getDate(),
    m = dt.getMonth() + 1 - subtractMonths,
    y = dt.getFullYear();
  return y + "-" + MonthNumbers[m - 1] + "-" + "01";
};

const subtractYearsFromDate = (date?: any, subtractMonths = 1) => {
  var dt = date ? new Date(date) : new Date();
  var d = dt.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = dt.getFullYear();
  return y - subtractMonths + "-" + m + "-" + "01";
};

export const getUCTToISTTTime = (a: Date) => {
  let d = new Date(a);
  return new Date(d.getTime() + 5.5 * 60 * 60 * 1000).toLocaleString();
};

export const convertStringValuesToNumbers = (obj: any) => {
  for (let key in obj) {
    if (typeof obj[key] === "string") {
      obj[key] = +obj[key];
    }
  }
  return obj;
};

export const dateForFirstOfMonthAndYesterdayDate = () => {
  let date = new Date();

  const yesterday = new Date(date);
  yesterday.setDate(new Date(date).getDate() - 1);

  let firstDay = new Date(
    new Date(yesterday).getFullYear(),
    new Date(yesterday).getMonth(),
    1
  );
  let obj = {
    yesterday: yesterday,
    firstDay: firstDay,
  };
  return obj;
};

export const convertMMM_YYToYYYY_MM = (input: string) => {
  // Create a mapping of month abbreviations to their respective numbers
  const monthMapping: any = {
    JAN: "01",
    FEB: "02",
    MAR: "03",
    APR: "04",
    MAY: "05",
    JUN: "06",
    JUL: "07",
    AUG: "08",
    SEP: "09",
    OCT: "10",
    NOV: "11",
    DEC: "12",
  };

  // Convert the input to uppercase to match the monthMapping keys
  const [monthAbbreviation, year] = input.toUpperCase().split("-");

  // Get the corresponding month number
  const month = monthMapping[monthAbbreviation];

  // If the month is valid, return the formatted string
  if (month) {
    return `20${year}-${month}`;
  } else {
    return "Invalid Date";
  }
};

export enum ENUM_OTS_SWITCH_FILTER_STATE {
  USER = "user",
  MARKET = "market",
}

export enum ENUM_OTS_DAY_WEEK_MONTH {
  DAY = "day",
  WEEK = "weekly",
  MONTH = "monthly",
}

export enum ENUM_STATUS_CODE {
  SUCCESS = 200,
  AUTHENDICATE = 401,
  CREATE = 201,
}

export enum ENUM_VERIFICATION_REPORT_FILTER {
  BUYER = "buyer",
  SUPPLIER = "supplier",
}

export enum ENUM_IS_VERIFIED {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum ENUM_IS_VERIFIED_FILTER {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
  ALL = "all",
}
export enum ENUM_CONSUMER_TYPE {
  CONSUMER = "consumer",
  PROSUMER = "prosumer",
  All = "all",
}

export enum ENUM_CONSUMER_STATUS {
  ACTIVE = "active",
  DEACTIVE = "inactive",
  All = "all",
}

export enum ENUM_EVENT_TIMLINE_FILTER {
  USER_CREATION = "NewUserAdded",
  TRADE = "NewTradeAdded",
  SETTELMENT = "SettlementEvent",
  BILL = "BillAdded",
}

export enum ENUM_PURCHASE_TYPE {
  BUY = "buy",
  SELL = "sell",
}

export enum BILL_TRANSACTION {
  DAILY = "daily",
  TRANSACTION = "transaction",
}

export enum ENUM_NEW_TRADE_MARKET_TYPE {
  INTRADAY = "intraday",
  DAY_AHEAD = "day_ahead",
  TERM_AHED = "term_ahead",
}

export enum ENUM_CHAINCODE_NAME {
  // USER = "user",
  // CONSUMER = "consumer",
  // LOAD_PROFILE = "loadprofile",
  // GENERATION_PROFILE = "generationprofile",
  // METER = "meter",
  TRADE = "trade",
  CONSUMPTION = "consumption",
  GENRATION = "generation",
  SETTLLEMENT = "settlement",
  BILL = "bill",
  // TARIFF = "tariff",
  // REC = "REC",
  TARRIFCODE = "tariffcode",
  DISCOM = "discom",
  //   USER_PRIVATE='userprivate',
  // CONSUMER_PRIVATE='consumerprivate'
  USER_PRIVATE = "user",
  CONSUMER_PRIVATE = "consumer",
  SYSTEM = "system"
}

export enum ENUM_ADMIN_ROLE {
  ADMIN = "admin",
  SUPER_ADMIN = "npcl_super_admin",
  DEVLOPER = 'developer'
}

// export function checkObjectKeyValue(val: any) {
//   if (typeof val === null || typeof val === undefined ||val==='') {
//     return "-";
//   }else if (typeof val === "number") {
//     return val?.toFixed(2);
//   } else if (typeof val === "object") {
//     return val;
//   } else if (typeof val === "boolean") {
//     return val?.toString();
//   } else if (typeof val === "string") {
//     const isoDate = dayjs(new Date(val));
//     const dateFormat = "DD-MM-YYYY";
//     const date = dayjs(new Date(val), dateFormat, true);
//     const timestamp = parseInt(val, 10);
//     dayjs.extend(utc);
//     dayjs.extend(timezone);
//     if (isoDate.isValid() && val.includes("T")) {

//       // return isoDate.format("DD-MM-YYYY HH:mm:ss"); 
//       return  dayjs.utc(new Date(val)).tz("Asia/Kolkata").format("DD-MM-YYYY HH:mm:ss")
//     } else if (date.isValid()) {
//       if (!isNaN(timestamp) && val.length === 13) {
//         // return dayjs(timestamp).format("DD-MM-YYYY HH:mm:ss");
//         return  dayjs.utc(new Date(val)).tz("Asia/Kolkata").format("DD-MM-YYYY HH:mm:ss")
//       } else {
//         return date.format("DD-MM-YYYY");

//       }
//     } else {
//       return val;
//     }
//   }   else {
//     return val;
//   }
// }

export function checkObjectKeyValue(val: any) {
  if (val === null || val === undefined || val === '') {
    return "-";
  } else if (typeof val === "number") {
    return val.toFixed(2);
  } else if (typeof val === "object") {
    return val;
  } else if (typeof val === "boolean") {
    return val.toString();
  } else if (typeof val === "string") {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const isoDate = dayjs(val);
    const dateFormat = "DD-MM-YYYY";
    const date = dayjs(val, dateFormat, true);
    const timestamp = parseInt(val, 10);

    if (isoDate.isValid() && val.includes("T")) {
      return dayjs.utc(val).tz("Asia/Kolkata").format("DD-MM-YYYY HH:mm:ss");
    } else if (!isNaN(timestamp) && val.length === 13) {
      return dayjs.utc(timestamp).tz("Asia/Kolkata").format("DD-MM-YYYY HH:mm:ss");
    } else if (date.isValid() && val.match(/^\d{2}-\d{2}-\d{4}$/)) {
      return date.format("DD-MM-YYYY");
    } else {
      return val;
    }
  } else {
    return val;
  }
}

// const color = [
//   "#1FC777", "#7750CE", "#CAB54B", "#117E4D", '#FBAA49', "#FD5EC3", "#333333", "#FC228A", "#7E5348", "#E0465E", "#C4C4C4",
//   "#B93555", "#A161D9", "#808080", "#5A9DF9", "#1487BE", "#0b4c8c", "##de4e2b", '#e8912d', '#e8912d', '#007a5a'
// ]

const blue = ["#d6e3f8", "#5a9df9", "#2146c7", "#0b4c8c", "#002a5c"];
const green = ["#bceed6", "#7bd5b8", "#1fc777", "#1e9870", "#117e4d"];
const red = ["#ffd3d3", "#ffa6a6", "#e5383b", "#c42348", "#d90429"];
const orange = ["#fdceb3", "#fa9d66", "#ff773d", "#de4e2b", "#e62314"];
const yellow = ["#ffffb7", "#ffea86", "#ffd454", "#fdb833", "#e8912d"];
const pink = ["#ffebf8", "#feafe1", "#fc228a", "#f20089", "#da007b"];
const voilet = ["#f2ebf8", "#c9ace2", "#9258c5", "#5b377b", "#3d0e61"];

let ERROR_CODES = [400, 500, 404, 503];

export {
  QUERIES,
  bussinessDaysInMonth,
  convertToCamelCase,
  getShortMonthYear,
  getFormatDate,
  getFormatToTime,
  getFormatToDateTime,
  getFormatDateKLocale,
  convertShortMonthToDate,
  getCurrentMonthYearNo,
  converMonthYearToMonthYearNo,
  getStartDateAndEndDateOfMonth,
  subtractMonthsFromDate,
  subtractYearsFromDate,
  convertToCamelCaseNormal,
  isValidStr,
  getStartDate,
  ERROR_CODES,
  getFormatToDateTimeWithoutSeconds,
  getStartDateWithoutObject,
  getFormattedDate,
  getFormatToDays,
  getFormatToDateTimeWithoutSecondsTogether,
  calculateMonthsFromBym,
};
