import { ENUM_ADMIN_ROLE } from "../../../../helpers";
import { Menu,MenuDeveloper,MenuSuperAdmin,otherMenuForHeading } from "./modules";


export const rollBaseModule=(roll:any)=>{
    switch(roll){
        case ENUM_ADMIN_ROLE?.ADMIN : return Menu;
        break;
        case ENUM_ADMIN_ROLE?.SUPER_ADMIN:return MenuSuperAdmin
        break;
        case ENUM_ADMIN_ROLE?.DEVLOPER:return MenuDeveloper
        break;
        default:return []
    }
}

export const rollBaseModuleHeading=(roll:any)=>{
    switch(roll){
        case ENUM_ADMIN_ROLE?.ADMIN : return [...Menu,...otherMenuForHeading];
        break;
        case ENUM_ADMIN_ROLE?.SUPER_ADMIN:return [...MenuSuperAdmin,...otherMenuForHeading]
        break;
        case ENUM_ADMIN_ROLE?.DEVLOPER:return [...MenuDeveloper,...otherMenuForHeading]
        break;
        default:return []
    }
}