import { Input } from 'src/components/ui/input'
import React, { useState, useRef, useMemo, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';


import axios, { AxiosError } from 'axios';

import { useNavigate } from 'react-router-dom';

import { toast } from 'sonner';

import { ENUM_STATUS_CODE } from 'src/helpers';

import FormSubmitBtn from 'src/components/commons/Buttons/FormSubmitBtn';
import { updateError, updateErrorCode } from 'src/redux/Reducers/errorHadlingSlicer';
import { loginApi, loginOtpValidateApi } from 'src/services/services';
import { loginReducerState, updateLoginData } from 'src/redux/Reducers/loginSlicer';
import { useAuth } from '../../Core';


const LOtp = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [otp, setOtp] = useState<string[]>(new Array(6).fill(''));
    const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(6).fill(null));

    const { setCurrentUser, saveAuth } = useAuth()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { phone, consumerNo, password, type,email } = useSelector((state: RootState) => loginReducerState(state))
    console.log(phone, consumerNo, password, type)

    const phoneNumber = useMemo(() => phone ? phone.toString().slice(-4) : '0789', [phone])
    const handleChange = (value: string, index: number) => {
        if (isNaN(Number(value))) return;
        const newOtp = [...otp];
        // console.log(newOtp)
        newOtp[index] = value;
        setOtp(newOtp);
        if (value === '' && index > 0 && inputRefs.current[index - 1]) {
            inputRefs.current[index - 1]?.focus();
        } else if (index < otp.length - 1 && inputRefs.current[index + 1]) {
            inputRefs.current[index + 1]?.focus();
        }

    }

    const otpVerification = async () => {

        setLoading(true)
        try {
            let postData = {
                phone: phone,
                otp: Number(otp.join(''))
            }
            let apiResp = await loginOtpValidateApi(postData);
            dispatch(updateErrorCode(apiResp.code))
            if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
            //   console.log(apiResp.code)
                setCurrentUser({
                    // ...apiResp?.data?.user,
                    userResponse: apiResp?.data?.user,
                    isAuthenticated: true,
                    user_id: apiResp?.data?.user?.id,
                    role:apiResp?.data?.user?.role
                    // ...apiResp?.data
                })
                
                saveAuth({
                    admin_token: apiResp.data?.tokens?.access?.token,
                    user_id: apiResp?.data?.user?.id,
                    consumerType: apiResp?.data?.user?.consumerType,
                    isLoadDetailsAvl: apiResp?.data?.user?.isLoadDetailsAvl,
                    isMeterDetailsAvl: apiResp?.data?.user?.isMeterDetailsAvl,
                    isPlantDetailsAvl: apiResp?.data?.user?.isPlantDetailsAvl ? apiResp?.data?.user?.isPlantDetailsAvl : false,
                    user_name: apiResp?.data?.user?.name,
                    isVerified: apiResp?.data?.user?.isVerified,
                    role:apiResp?.data?.user?.role,
                    disom:apiResp?.data&&apiResp?.data?.user?.disom,
                    countryCode:apiResp?.data&&apiResp?.data?.countryCode,
                    state:apiResp?.data&&apiResp?.data?.state
                    
                })

            } else {
                dispatch(updateErrorCode(apiResp.code))
                dispatch(updateError(apiResp.message))
                // toast.error(apiResp.message)
            }
            return apiResp.data
        } catch (err) {
            let error = err as Error | AxiosError
            if (axios.isAxiosError(error)) {
                dispatch(updateErrorCode(error.response?.data.code))
                dispatch(updateError(error.response?.data.message))

            }
            else {

                dispatch(updateError(error.message))

                toast.error(error.message)
            }
        } finally {
            setLoading(false)
        }
    }

    const resendAgainLogin = async () => {

        setOtp(new Array(6).fill(''))
        try {
            let postdata = {
                consumerNo: consumerNo,
                type: type,
                phone: phone,
                password: password,
                email:email

            }

            let apiResp = await loginApi(postdata);
            dispatch(updateErrorCode(apiResp.code))
            if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {

                dispatch(updateLoginData(apiResp?.data))
                setOtp(new Array(6).fill(''));
            } else {
                dispatch(updateErrorCode(apiResp.code))
                dispatch(updateError(apiResp.message))
                // toast.error(apiResp.message)
            }
            return apiResp.data
        } catch (err) {
            let error = err as Error | AxiosError
            if (axios.isAxiosError(error)) {
                dispatch(updateErrorCode(error.response?.data.code))
                dispatch(updateError(error.response?.data.message))
                // toast.error(error.response?.data.message)
            } else {
                dispatch(updateError(error.message))


            }
        }
    }

    const isOtpComplete = otp.every(val => val !== '');

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            //   console.log('User pressed: ', event.key);

            if (event.key === 'Enter') {
                event.preventDefault();

                // 👇️ Call submit function here
                otpVerification();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [otp]);

    return (
        <div className='w-full lg:flex lg:flex-col lg:items-center lg:justify-center'>
            <h1 className='text-GlobalColor font-bold text-xl text-center md:text-2xl '>Verification</h1>
            <div className='w-full flex  justify-center items-center mt-6'>
                <p className=' text-zinc-600 text-sm text-left md:text-center lg:text-left  w-3/4 flex font-[500] md:text-sm  lg:font-normal lg:w-3/5'>Please enter the verification code received on your registered mobile number</p>
            </div>

            <p className='font-medium  text-black text-sm my-2 text-center   lg:font-medium  lg:text-left w-full lg:w-3/5 lg:my-3 ' >******{phoneNumber}</p>
            <p className='w-full font-normal text-center text-zinc-600 text-sm mt-2 mb-6 md:text-sm  lg:font-normal lg:text-left  lg:w-3/5'>The code will expire in 14:20</p>
            <div className='w-full flex space-x-4 justify-center '>
                {otp.map((cur, index) => (
                    <Input
                        key={index}
                        maxLength={1}
                        className='w-10 h-10 border-input border-grayLable rounded-lg  '
                        value={cur}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, index)}
                        onFocus={e => e.target.select()}
                        ref={el => inputRefs.current[index] = el}
                        data-qa={`otp_${index}`}

                    />
                ))}
            </div>
            <div className='w-full px-8 mt-4 md:w-3/4 lg:flex lg:justify-center'>
                {/* <Button  onClick={otpVerification} className='w-full bg-[#34C657] font-semibold text-sm rounded-full mt-2  lg:w-3/4 '>VERIFY</Button> */}
                <FormSubmitBtn onClick={otpVerification} btnName={`${isOtpComplete ? 'CONFIRM' : 'VERIFY'}`} data-qa='otpVerifys' id='otpVerify'
                    // disabled={!isOtpComplete} 
                    loading={loading} />
            </div>
            <h3 className='text-zinc-500 text-sm font-medium text-center mt-4 md:my-4 '>Didn’t receive any code?<span className='text-zinc-700 text-sm font-semibold cursor-pointer' onClick={resendAgainLogin}>Resend Again</span> </h3>
        </div>
    )
}

export default LOtp


